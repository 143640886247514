import { dealerService } from 'core-tracking-objects';
import { trackingAnalyticsApi } from '../api/analyticsApi';

class DealerTracking {
	/**
	 * @constructor singleton
	 */
	constructor() {
		if (DealerTracking._instance) {
			return DealerTracking._instance;
		}

		this.initialize();

		DealerTracking._instance = this;
	}

	/**
	 * initialize class
	 * @returns {void}
	 */
	initialize() {
		this._addEvents();
	}

	/**
	 * add custom event listeners
	 * @returns {void}
	 */
	_addEvents() {
		document.addEventListener('DEALER_LOADED', this._saveTrackingData);
	}

	/**
	 * saves page data via api call
	 * @returns {void}
	 */
	async _saveTrackingData() {
		const dealerData = await dealerService.getData();

		// add dealer data only if not empty
		if (dealerData.length > 0) {
			const trackingObject = { dealer: dealerData };
			trackingAnalyticsApi.storeObjectsInDigitalData(trackingObject);
		}
	}
}

/**
 * Singleton instance
 * @type {DealerTracking}
 * @private
 * @static
 */

const dealerTracking = new DealerTracking();
export { dealerTracking, DealerTracking };
