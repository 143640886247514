import { clickEventService } from 'core-tracking-objects';
import { trackingAnalyticsApi } from '../api/analyticsApi';

class ClickTracking {
	/**
	 * @constructor singleton
	 */
	constructor() {
		if (ClickTracking._instance) {
			return ClickTracking._instance;
		}

		this.initialize();

		ClickTracking._instance = this;
	}

	/**
	 * initialize class
	 * @returns {void}
	 */
	initialize() {
		this._handleClickEvent = this._handleClickEvent.bind(this);
		this._saveTrackingData = this._saveTrackingData.bind(this);
		this.currentLocation = window.location.href;

		document.body.addEventListener('click', this._handleClickEvent);
	}

	/**
	 * react to a click event
	 * @param {Event} event_ - click event
	 * @returns {void}
	 */
	_handleClickEvent(event_) {
		if (
			event_ &&
			event_.target &&
			!this._isElementExcludedFromTracking(event_.target)
		) {
			this._saveTrackingData(event_.target);
		}
	}

	/**
	 * saves page data via api call
	 * @param {Element} clickedElement_ - the clicked element
	 * @returns {void}
	 */
	async _saveTrackingData(clickedElement_) {
		let eventObject = await clickEventService.getData(
			clickedElement_,
			this.currentLocation,
		);

		if (Object.keys(eventObject).length === 0) {
			return;
		}

		trackingAnalyticsApi.storeEventsInDigitalData(eventObject);
		this.currentLocation = window.location.href;
	}

	/**
	 * tracking should only happen if there is an explicit tracking include flag for feature apps and/or no exlusion flag set
	 * @param {Element} domElement_ - the clicked element
	 * @returns {boolean} true | false depending on wether element is excluded
	 */
	_isElementExcludedFromTracking(domElement_) {
		const hasNemoExcludeFlag = domElement_.dataset.trackingExclude;
		const elemenWithExcludeFlag = domElement_.closest(
			'[data-audi-core-tracking-exclude]',
		);
		const featureAppTag = domElement_.closest('feature-app');

		let featureAppTagHasIncludeAttribute = false;
		let featureAppTagContainsIncludeAttribute = false;

		if (featureAppTag) {
			const innerElementWithIncludeFlag = featureAppTag.querySelector(
				'[data-audi-core-tracking-include="true"]',
			);
			featureAppTagHasIncludeAttribute = featureAppTag.getAttribute(
				'data-audi-core-tracking-include',
			);
			featureAppTagContainsIncludeAttribute =
				innerElementWithIncludeFlag &&
				innerElementWithIncludeFlag.contains(domElement_);
		}

		const includeFeatureApp =
			featureAppTagHasIncludeAttribute ||
			featureAppTagContainsIncludeAttribute;

		if (
			hasNemoExcludeFlag ||
			(featureAppTag && !includeFeatureApp) ||
			(featureAppTag &&
				includeFeatureApp &&
				elemenWithExcludeFlag &&
				featureAppTag.contains(elemenWithExcludeFlag)) ||
			(!featureAppTag && elemenWithExcludeFlag)
		) {
			return true;
		}

		return false;
	}
}

/**
 * Singleton instance
 * @type {ClickTracking}
 * @private
 * @static
 */
const clickTracking = new ClickTracking();
export { clickTracking, ClickTracking };
