(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-tracking-objects"));
	else if(typeof define === 'function' && define.amd)
		define("core-tracking-events", ["core-tracking-objects"], factory);
	else if(typeof exports === 'object')
		exports["core-tracking-events"] = factory(require("core-tracking-objects"));
	else
		root["core-tracking-events"] = factory(root["core-tracking-objects"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__525__) => {
return 