import { searchService } from 'core-tracking-objects';
import { trackingAnalyticsApi } from '../api/analyticsApi';

class SearchTracking {
	/**
	 * @constructor singleton
	 */
	constructor() {
		if (SearchTracking._instance) {
			return SearchTracking._instance;
		}

		this.initialize();

		SearchTracking._instance = this;
	}

	/**
	 * initialize class
	 * @returns {void}
	 */
	initialize() {
		this._saveTrackingData = this._saveTrackingData.bind(this);

		document.addEventListener('SEARCH_UPDATED', this._saveTrackingData);
	}

	/**
	 * collects search tracking datas and saves data via api call
	 * @returns {void}
	 */
	async _saveTrackingData() {
		let trackingObject = {};
		const searchData = await searchService.getData();

		// add search data only if not empty
		if (Object.keys(searchData).length > 0) {
			trackingObject.search = searchData;

			trackingAnalyticsApi.storeObjectsInDigitalData(trackingObject);
		}
	}
}

/**
 * Singleton instance
 * @type {SearchTracking}
 * @private
 * @static
 */

const searchTracking = new SearchTracking();
export { searchTracking, SearchTracking };
