import { cartService } from 'core-tracking-objects';
import { trackingAnalyticsApi } from '../api/analyticsApi';

class ConfigurationTracking {
	/**
	 * @constructor singleton
	 */
	constructor() {
		if (ConfigurationTracking._instance) {
			return ConfigurationTracking._instance;
		}

		this.initialize();

		ConfigurationTracking._instance = this;
	}

	/**
	 * initialize class
	 * @returns {void}
	 */
	initialize() {
		this._saveTrackingData = this._saveTrackingData.bind(this);
		this._addEvents();
	}

	/**
	 * add custom event listeners
	 * @returns {void}
	 */
	_addEvents() {
		document.addEventListener(
			'configuration-tracking',
			this._saveTrackingData,
		);
	}

	/**
	 * saves page data via api call
	 * @param {Event} event_ - tracking event
	 * @returns {void}
	 */
	async _saveTrackingData(event_) {
		if (event_ && event_.detail && Object.keys(event_.detail).length > 0) {
			const trackingObject = await this._getCartData();
			if (Object.keys(trackingObject).length > 0) {
				trackingAnalyticsApi.storeObjectsInDigitalData(trackingObject);
			}
			trackingAnalyticsApi.storeEventsInDigitalData(event_.detail);
		}
	}

	/**
	 * collects object tracking data
	 * @returns {Promise} object tracking data as promise
	 */
	async _getCartData() {
		const cartData = await cartService.getData();
		const trackingObject = {};

		// add cart data only if not empty
		if (
			cartData &&
			(Object.keys(cartData).length !== 0 ||
				cartData.constructor !== Object)
		) {
			trackingObject.cart = cartData;
		}

		return trackingObject;
	}
}

/**
 * Singleton instance
 * @type {ConfigurationTracking}
 * @private
 * @static
 */

const configurationTracking = new ConfigurationTracking();
export { configurationTracking, ConfigurationTracking };
