import { cartService } from 'core-tracking-objects';
import { componentService } from 'core-tracking-objects';
import { dealerService } from 'core-tracking-objects';
import { pageService } from 'core-tracking-objects';
import { productService } from 'core-tracking-objects';
import { searchService } from 'core-tracking-objects';
import { stateEventService } from 'core-tracking-objects';
import { trackingAnalyticsApi } from '../api/analyticsApi';
import { userService } from 'core-tracking-objects';

class PageTracking {
	/**
	 * @constructor singleton
	 */
	constructor() {
		if (PageTracking._instance) {
			return PageTracking._instance;
		}

		this.initialize();

		PageTracking._instance = this;
	}

	/**
	 * initialize class
	 * @returns {void}
	 */
	initialize() {
		this._saveTrackingData = this._saveTrackingData.bind(this);

		this._saveTrackingData();
		this._addEvents();
	}

	/**
	 * add custom event listeners
	 * @returns {void}
	 */
	_addEvents() {
		document.addEventListener('PAGE_READY', this._saveTrackingData);
		document.addEventListener('LAYER_LOADED', this._saveTrackingData);
		document.addEventListener('LAYER_CLOSED', this._saveTrackingData);
	}

	/**
	 * saves page data via api call
	 * @param {Event} event_ - tracking event
	 * @returns {void}
	 */
	async _saveTrackingData(event_) {
		const eventType = event_ !== undefined ? event_.type : '';

		const trackingObject = await this._getObjectData();
		trackingAnalyticsApi.storeObjectsInDigitalData(trackingObject);

		const eventStateData = await this._getEventData(eventType);
		trackingAnalyticsApi.storeEventsInDigitalData(eventStateData);
	}

	/**
	 * collects object tracking data
	 * @returns {Promise} object tracking data as promise
	 */
	async _getObjectData() {
		const productData = await this._getProductData();
		const cartData = await this._getCartData();
		const searchData = await this._getSearchData();
		const dealerData = await this._getDealerData();

		const trackingObject = {
			component: await this._getComponentData(),
			page: await this._getPageData(),
			user: [await this._getUserData()],
		};

		// add product data only if not empty
		if (
			Object.keys(productData).length !== 0 ||
			productData.constructor !== Array
		) {
			trackingObject.product = productData;
		}

		// add cart data only if not empty
		if (
			Object.keys(cartData).length !== 0 ||
			cartData.constructor !== Object
		) {
			trackingObject.cart = cartData;
		}

		// add search data only if not empty
		if (
			Object.keys(searchData).length !== 0 ||
			searchData.constructor !== Object
		) {
			trackingObject.search = searchData;
		}

		// add dealer data only if not empty
		if (dealerData.length > 0) {
			trackingObject.dealer = dealerData;
		}

		return trackingObject;
	}

	/**
	 * collects event tracking data
	 * @param {Event} eventType_ - tracking event type
	 * @returns {Promise} event tracking data as promise
	 */
	async _getEventData(eventType_) {
		return await stateEventService.getData(eventType_);
	}

	/**
	 * collects page tracking data
	 * @returns {Promise} page tracking data as promise
	 */
	async _getPageData() {
		return await pageService.getData();
	}

	/**
	 * collects component tracking data
	 * @returns {Promise} component tracking data as promise
	 */
	async _getComponentData() {
		return await componentService.getData();
	}

	/**
	 * collects product tracking data
	 * @returns {Promise} product tracking data as promise
	 */
	async _getProductData() {
		return await productService.getData();
	}

	/**
	 * collects cart tracking data
	 * @returns {Promise} cart tracking data as promise
	 */
	async _getCartData() {
		return await cartService.getData();
	}

	/**
	 * collects user tracking data
	 * @returns {Promise} user tracking data as promise
	 */
	async _getUserData() {
		return await userService.getData();
	}

	/**
	 * collects search tracking data
	 * @returns {Promise} search tracking data as promise
	 */
	async _getSearchData() {
		return await searchService.getData();
	}

	/**
	 * collects dealer tracking data
	 * @returns {Promise} search tracking data as promise
	 */
	async _getDealerData() {
		return await dealerService.getData();
	}
}

/**
 * Singleton instance
 * @type {PageTracking}
 * @private
 * @static
 */

const pageTracking = new PageTracking();
export { pageTracking, PageTracking };
