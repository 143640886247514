import { CustomEventService } from 'core-tracking-objects';
import { trackingAnalyticsApi } from '../api/analyticsApi';

class CustomEventTracking {
	constructor() {
		if (CustomEventTracking._instance) {
			return CustomEventTracking._instance;
		}
		CustomEventTracking._instance = this;

		this._saveTrackingData = this._saveTrackingData.bind(this);

		this._addEventListeners();
	}

	/**
	 * add event listeners
	 * @returns {void}
	 */
	_addEventListeners() {
		document.addEventListener(
			CustomEventService.CUSTOM_TRACKING_EVENT,
			this._saveTrackingData,
		);
	}

	/**
	 * saves page data via api call
	 * @param {Event} event_ - the custom element
	 * @returns {void}
	 */
	_saveTrackingData(event_) {
		const eventObject = event_.detail;

		if (!eventObject || Object.keys(eventObject).length === 0) {
			return;
		}

		trackingAnalyticsApi.storeEventsInDigitalData(eventObject);
	}
}

const customEventTracking = new CustomEventTracking();
export { customEventTracking, CustomEventTracking };
