import { cartService } from 'core-tracking-objects';
import { trackingAnalyticsApi } from '../api/analyticsApi';

class CartTracking {
	/**
	 * @constructor singleton
	 */
	constructor() {
		if (CartTracking._instance) {
			return CartTracking._instance;
		}

		this.initialize();

		CartTracking._instance = this;
	}

	/**
	 * initialize class
	 * @returns {void}
	 */
	initialize() {
		this._saveTrackingData = this._saveTrackingData.bind(this);

		this._saveTrackingData();
		this._addEvents();
	}

	/**
	 * add custom event listeners
	 * @returns {void}
	 */
	_addEvents() {
		document.addEventListener(
			'configuration-update-tracking',
			this._saveTrackingData,
		);
	}

	/**
	 * saves page data via api call
	 * @returns {void}
	 */
	async _saveTrackingData() {
		const trackingObject = await this._getObjectData();
		if (Object.keys(trackingObject).length > 0) {
			trackingAnalyticsApi.storeObjectsInDigitalData(trackingObject);
		}
	}

	/**
	 * collects object tracking data
	 * @returns {Promise} object tracking data as promise
	 */
	async _getObjectData() {
		const cartData = await cartService.getData();
		const trackingObject = {};

		// add cart data only if not empty
		if (
			Object.keys(cartData).length !== 0 ||
			cartData.constructor !== Object
		) {
			trackingObject.cart = cartData;
		}

		return trackingObject;
	}
}

/**
 * Singleton instance
 * @type {CartTracking}
 * @private
 * @static
 */

const cartTracking = new CartTracking();
export { cartTracking, CartTracking };
